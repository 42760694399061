export default {
	name: "ListaTema",
	data() {
		return {
			arTemas: [],
			arAssuntos: [],
			blLoading: true,
			obForm: {
				idAssunto: null
			},
			obFormPesquisa: {
				idAssunto: null
			}
		};
	},
	created() {
		this.listar();
	},
	methods: {
		listar() {
			this.$root.$api.get("tema/listar").then(response => {
				this.arTemas = response.retorno.temas;
				this.arAssuntos = response.retorno.assuntos;
				this.blLoading = false;
			});
		},
		filtrar() {
			this.obFormPesquisa.idAssunto = this.obForm.idAssunto;
		},
	},
	computed: {
		listaTemas() {
			if (!this.obFormPesquisa.idAssunto)
				return this.arTemas;

			return this.arTemas.filter(obTema => {
				return obTema.id_assunto == this.obFormPesquisa.idAssunto
			})
		}
	},
};